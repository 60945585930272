const songs = [
  {
    id: '6D79CYTxvOM',
    image: 'album1',
    title: '愛人錯過',
    albumId: 'album1',
    album: '我肯定在幾百年前就說過愛你',
    artist: '告五人',
    time: '318',
  },
  {
    id: '9WEYFqCUze8',
    image: 'album1',
    title: '紅',
    albumId: 'album1',
    album: '我肯定在幾百年前就說過愛你',
    artist: '告五人',
    time: '364',
  },
  {
    id: 'VpwAq7hiij0',
    image: 'album1',
    title: '披星戴月的想你',
    albumId: 'album1',
    album: '我肯定在幾百年前就說過愛你',
    artist: '告五人',
    time: '465',
  },
  {
    id: 'Chua3NCdFGA',
    image: 'album1',
    title: '法蘭西多士',
    albumId: 'album1',
    album: '我肯定在幾百年前就說過愛你',
    artist: '告五人',
    time: '275',
  },
  {
    id: 'U_38EfiMoeY',
    image: 'album1',
    title: '跳海',
    albumId: 'album1',
    album: '我肯定在幾百年前就說過愛你',
    artist: '告五人',
    time: '290',
  },
  {
    id: 'E0WZKnHfvFA',
    image: 'album1',
    title: '果然你還是',
    albumId: 'album1',
    album: '我肯定在幾百年前就說過愛你',
    artist: '告五人',
    time: '378',
  },
  {
    id: 'GuNgN0brjR4',
    image: 'album1',
    title: '夜裡無星',
    albumId: 'album1',
    album: '我肯定在幾百年前就說過愛你',
    artist: '告五人',
    time: '328',
  },
  {
    id: 'ZaQGkvKDgLA',
    image: 'album1',
    title: '驕傲的鯨魚',
    albumId: 'album1',
    album: '我肯定在幾百年前就說過愛你',
    artist: '告五人',
    time: '306',
  },
  {
    id: 's_XnRpIuN4Q',
    image: 'album1',
    title: '從沒去過巴塞隆納',
    albumId: 'album1',
    album: '我肯定在幾百年前就說過愛你',
    artist: '告五人',
    time: '273',
  },
  {
    id: 'd49y7fqbpYU',
    image: 'album1',
    title: '不具名的花',
    albumId: 'album1',
    album: '我肯定在幾百年前就說過愛你',
    artist: '告五人',
    time: '339',
  },
  {
    id: 'H0X8lR_bCv8',
    image: 'album1',
    title: '簡答題',
    albumId: 'album1',
    album: '我肯定在幾百年前就說過愛你',
    artist: '告五人',
    time: '168',
  },
  {
    id: 'vxucCfcMFCk',
    image: 'album2',
    title: '唯一',
    albumId: 'album2',
    album: '運氣來得若有似無',
    artist: '告五人',
    time: '272',
  },
  {
    id: 'N9Pi50O7ZR4',
    image: 'album2',
    title: '醜人多作怪',
    albumId: 'album2',
    album: '運氣來得若有似無',
    artist: '告五人',
    time: '261',
  },
  {
    id: 'PlCbgZxonJs',
    image: 'album2',
    title: '在這座城市遺失了你',
    albumId: 'album2',
    album: '運氣來得若有似無',
    artist: '告五人',
    time: '398',
  },
  {
    id: 'Ay5wB1e1ORc',
    image: 'album2',
    title: '同樣一個你',
    albumId: 'album2',
    album: '運氣來得若有似無',
    artist: '告五人',
    time: '323',
  },
  {
    id: 'caxiOmSWWEM',
    image: 'album3',
    title: '好不容易',
    albumId: 'album3',
    album: '好不容易',
    artist: '告五人',
    time: '328'
  },
  {
    id: '2n7EP62ocHM',
    image: 'album4',
    title: '慢靈魂',
    albumId: 'album4',
    album: '慢靈魂',
    artist: '盧廣仲',
    time: '324'
  },
  {
    id: 'Z7cnN8rMoQU',
    image: 'album4',
    title: '藍寶',
    albumId: 'album4',
    album: '慢靈魂',
    artist: '盧廣仲',
    time: '227'
  },
  {
    id: 'pZSegLuIp2E',
    image: 'album5',
    title: '七天',
    albumId: 'album5',
    album: '七天',
    artist: '盧廣仲',
    time: '234'
  },
  {
    id: 'APSWDo2UelQ',
    image: 'album5',
    title: 'OH YEAH!!!',
    albumId: 'album5',
    album: '七天',
    artist: '盧廣仲',
    time: '225'
  },
  {
    id: 'mIhDDOtR418',
    image: 'album5',
    title: '聽見了嗎',
    albumId: 'album5',
    album: '七天',
    artist: '盧廣仲',
    time: '274'
  },
  {
    id: '58EaveQ5Xig',
    image: 'album5',
    title: '再見勾勾',
    albumId: 'album5',
    album: '七天',
    artist: '盧廣仲',
    time: '242'
  },
  {
    id: '4bSeT_9gmJs',
    image: 'album6',
    title: '安九',
    albumId: 'album6',
    album: '吾日三省吾身',
    artist: '老王樂隊',
    time: '394'
  },
  {
    id: 'MTjWhqsO5Bc',
    image: 'album6',
    title: '曾經的女人啊 你在哪裡 你在哪裡',
    albumId: 'album6',
    album: '吾日三省吾身',
    artist: '老王樂隊',
    time: '356'
  },
  {
    id: 'CLgSrAOmVAw',
    image: 'album6',
    title: '那些失眠的夜與難以忘懷的事',
    albumId: 'album6',
    album: '吾日三省吾身',
    artist: '老王樂隊',
    time: '240'
  },
  {
    id: 'CCZEblcb1rc',
    image: 'album6',
    title: '再等一下就天亮了',
    albumId: 'album6',
    album: '吾日三省吾身',
    artist: '老王樂隊',
    time: '260'
  },
  {
    id: 'NbNPJr_0tqA',
    image: 'album7',
    title: '我還年輕 我還年輕',
    albumId: 'album7',
    album: '吾十有五而志於學',
    artist: '老王樂隊',
    time: '355'
  },
  {
    id: 'b8Wl2huUVPw',
    image: 'album7',
    title: '穩定生活多美好 三年五年高普考',
    albumId: 'album7',
    album: '吾十有五而志於學',
    artist: '老王樂隊',
    time: '330'
  },
  {
    id: 'p2OImxGvfSA',
    image: 'album7',
    title: '補習班的門口高掛我的黑白照片',
    albumId: 'album7',
    album: '吾十有五而志於學',
    artist: '老王樂隊',
    time: '402'
  },
];

export default songs;
